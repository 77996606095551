import Vue from "vue";
import VueRouter from "vue-router";

import AccountApplication from "../pages/AccountApplication";
import AccountApplicationConfirm from "../pages/AccountApplication/Confirm";
import NotFound from "../pages/NotFound";
import Register from "../pages/Register.vue";
import ForProposer from "../pages/ForProposer.vue";
import Top from "../pages/Top.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/account",
    component: AccountApplication,
  },
  {
    path: "/account/confirm",
    component: AccountApplicationConfirm,
  },
  {
    name: "NotFound",
    path: "*",
    component: NotFound,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/for_proposer",
    component: ForProposer,
  },
  {
    path: "",
    component: Top,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
});

export default router;
