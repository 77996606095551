import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    first_name: "",
    middle_name: "",
    last_name: "",
    suffix: "",
    email: "",
    affiliation: "",
    early_career: "",
    proposal_title: "",
    proposal_abstract: "",
    collaborators: [],
  },
  mutations: {
    updateFirstName(state, first_name) {
      state.first_name = first_name;
    },
    updateMiddleName(state, middle_name) {
      state.middle_name = middle_name;
    },
    updateLastName(state, last_name) {
      state.last_name = last_name;
    },
    updateSuffix(state, suffix) {
      state.suffix = suffix;
    },
    updateEmail(state, email) {
      state.email = email;
    },
    updateAffiliation(state, affiliation) {
      state.affiliation = affiliation;
    },
    updateEarlyCareer(state, early_career) {
      state.early_career = early_career;
    },
    updateProposalTitle(state, proposal_title) {
      state.proposal_title = proposal_title;
    },
    updateProposalAbstract(state, proposal_abstract) {
      state.proposal_abstract = proposal_abstract;
    },
    updateCollaborators(state, collaborators) {
      state.collaborators = collaborators;
    },
  },
  actions: {},
  modules: {},
});
