<template>
  <v-app>
    <v-container fluid class="application">
      <v-row justify="center" align="center">
        <v-col xs="10" md="10">
          <div class="text-center mb-2">
            <img src="../../assets/logo.png" width="100" class="mx-auto mb-4" />
          </div>
          <div class="text-center mb-6">
            <h3 class="white--text">
              Ryugu Sample AO System Account Application (Notice of Intent for the {{ termText }} AO)
            </h3>
          </div>
          <v-card color="grey lighten-5" class="mb-5">
            <v-toolbar color="grey lighten-2" flat>
              <h3>Proposer</h3>
            </v-toolbar>
            <v-divider> </v-divider>
            <div class="pa-6 pb-0">
              <v-row dense>
                <v-col cols="6">
                  <v-row dense>
                    <v-col cols="6">
                      <div class="d-flex justify-space-between">
                        <v-text-field
                          outlined
                          label="First Name*"
                          color="blue darken-4"
                          background-color="white"
                          placeholder="Taro"
                          v-model="first_name"
                          style="width: 60%"
                          :error-messages="
                            this.errors.first_name ? this.errors.first_name[0] :''
                          "
                          :error-count="
                            this.errors.first_name ? this.errors.first_name.length : 0
                          "
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="Middle Name"
                          color="black"
                          background-color="white"
                          placeholder=""
                          v-model="middle_name"
                          style="width: 37%; margin-left: 3%"
                          :error-messages="
                            this.errors.middle_name ? this.errors.middle_name[0] :''
                          "
                          :error-count="
                            this.errors.middle_name ? this.errors.middle_name.length : 0
                          "
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="d-flex justify-space-between">
                        <v-text-field
                          outlined
                          label="Last Name*"
                          color="blue darken-4"
                          background-color="white"
                          placeholder="Suzuki"
                          v-model="last_name"
                          style="width: 60%"
                          :error-messages="
                            this.errors.last_name ? this.errors.last_name[0] :''
                          "
                          :error-count="
                            this.errors.last_name ? this.errors.last_name.length : 0
                          "
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="Suffix"
                          color="black"
                          background-color="white"
                          placeholder=""
                          v-model="suffix"
                          style="width: 37%; margin-left: 3%"
                          :error-messages="
                            this.errors.suffix ? this.errors.suffix[0] :''
                          "
                          :error-count="
                            this.errors.suffix ? this.errors.suffix.length : 0
                          "
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        outlined
                        label="E-Mail*"
                        color="blue darken-4"
                        background-color="white"
                        placeholder="taro@example.com"
                        v-model="email"
                        :error-messages="
                          this.errors.email ? this.errors.email[0] :''
                        "
                        :error-count="
                          this.errors.email ? this.errors.email.length : 0
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        outlined
                        label="Affiliation*"
                        color="black"
                        background-color="white"
                        placeholder="Example Univ."
                        v-model="affiliation"
                        :error-messages="
                          this.errors.affiliation ? this.errors.affiliation[0] :''
                        "
                        :error-count="
                          this.errors.affiliation ? this.errors.affiliation.length : 0
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div class="font-weight-bold">Early Career Scientist</div>
              <v-radio-group row v-model="early_career">
                <v-radio
                  v-for="earlyCareer in earlyCareerScientists"
                  :key="earlyCareer.id"
                  :label="earlyCareer.label"
                  :value="earlyCareer.id"
                  color="blue darken-4"
                ></v-radio>
              </v-radio-group>

              <div class="font-weight-bold mb-3">
                Tentative title of the proposal*
              </div>
              <v-text-field
                outlined
                solo
                dense
                flat
                class="mb-4"
                color="black"
                v-model="proposal_title"
                :error-messages="
                  this.errors.proposal_title ? this.errors.proposal_title[0] :''
                "
                :error-count="
                  this.errors.proposal_title ? this.errors.proposal_title.length : 0
                "
              ></v-text-field>
              <div class="font-weight-bold mb-3">
                Brief description of the proposal (up to 150 words)*
              </div>
              <v-textarea
                outlined
                solo
                dense
                flat
                color="black"
                v-model="proposal_abstract"
                :rules="briefRules"
                :error-messages="
                  this.errors.proposal_abstract ? this.errors.proposal_abstract[0] :''
                "
                :error-count="
                  this.errors.proposal_abstract ? this.errors.proposal_abstract.length : 0
                "
              ></v-textarea>
              <div class="text-right">{{ word_count }} words</div>
            </div>
          </v-card>

          <transition-group tag="div">
            <template v-for="(collaborator, index) in collaborators">
              <v-card color="grey lighten-5" class="mb-5" :key="index">
                <v-card-title>
                  <h4>
                    Collaborator<v-chip
                      filter
                      label
                      color="blue lighten-4"
                      class="ml-3"
                      disabled
                      small
                      >{{ collaborator.title }}</v-chip
                    >
                  </h4>
                </v-card-title>
                <div class="pa-6">
                  <v-row dense>
                    <v-col cols="6">
                      <v-row dense>
                        <v-col cols="6">
                          <div class="d-flex justify-space-between">
                            <div class="v-input__control" style="width: 60%;">
                              <v-text-field
                                outlined
                                label="First Name*"
                                class="mb-4"
                                background-color="white"
                                color="black"
                                placeholder="Taro"
                                hide-details
                                v-model="collaborator.first_name"
                                :error-messages="
                                  errors['collaborators.' + index + '.first_name'] ? errors['collaborators.' + index + '.first_name'] :''
                                "
                              ></v-text-field>
                              <div
                                class="v-text-field__details"
                                v-if="
                                  errors['collaborators.' + index + '.first_name']
                                "
                              >
                                <div class="v-messages theme--light error--text" role="alert">
                                  <div class="v-messages__wrapper">
                                    <div class="messages__message">{{ errors['collaborators.' + index + '.first_name'][0] }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="v-input__control" style="width: 37%; margin-left: 3%">
                              <v-text-field
                                outlined
                                label="Middle Name"
                                class="mb-4"
                                background-color="white"
                                color="black"
                                hide-details
                                v-model="collaborator.middle_name"
                                :error-messages="
                                  errors['collaborators.' + index + '.middle_name'] ? errors['collaborators.' + index + '.middle_name'] :''
                                "
                              ></v-text-field>
                              <div
                                class="v-text-field__details"
                                v-if="
                                  errors['collaborators.' + index + '.middle_name']
                                "
                              >
                                <div class="v-messages theme--light error--text" role="alert">
                                  <div class="v-messages__wrapper">
                                    <div class="messages__message">{{ errors['collaborators.' + index + '.middle_name'][0] }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex justify-space-between">
                            <div class="v-input__control" style="width: 60%;">
                              <v-text-field
                                outlined
                                label="Last Name*"
                                class="mb-4"
                                background-color="white"
                                color="black"
                                placeholder="Suzuki"
                                hide-details
                                v-model="collaborator.last_name"
                                :error-messages="
                                  errors['collaborators.' + index + '.last_name'] ? errors['collaborators.' + index + '.last_name'] :''
                                "
                              ></v-text-field>
                              <div
                                class="v-text-field__details"
                                v-if="
                                  errors['collaborators.' + index + '.last_name']
                                "
                              >
                                <div class="v-messages theme--light error--text" role="alert">
                                  <div class="v-messages__wrapper">
                                    <div class="messages__message">{{ errors['collaborators.' + index + '.last_name'][0] }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="v-input__control" style="width: 37%; margin-left: 3%">
                              <v-text-field
                                outlined
                                label="Suffix"
                                class="mb-4"
                                background-color="white"
                                color="black"
                                hide-details
                                v-model="collaborator.suffix"
                                :error-messages="
                                  errors['collaborators.' + index + '.suffix'] ? errors['collaborators.' + index + '.suffix'] :''
                                "
                              ></v-text-field>
                              <div
                                class="v-text-field__details"
                                v-if="
                                  errors['collaborators.' + index + '.suffix']
                                "
                              >
                                <div class="v-messages theme--light error--text" role="alert">
                                  <div class="v-messages__wrapper">
                                    <div class="messages__message">{{ errors['collaborators.' + index + '.suffix'][0] }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row dense>
                        <v-col>
                          <v-text-field
                            outlined
                            label="E-Mail*"
                            class="mb-4"
                            background-color="white"
                            color="black"
                            placeholder="taro@example.com"
                            hide-details
                            v-model="collaborator.email"
                            :error-messages="
                              errors['collaborators.' + index + '.email'] ? errors['collaborators.' + index + '.email'] :''
                            "
                          ></v-text-field>
                          <div
                            class="v-text-field__details"
                            v-if="
                              errors['collaborators.' + index + '.email']
                            "
                          >
                            <div class="v-messages theme--light error--text" role="alert">
                              <div class="v-messages__wrapper">
                                <div class="messages__message">{{ errors['collaborators.' + index + '.email'][0] }}</div>
                              </div>
                            </div>
                          </div>
                        </v-col>
                        <v-col>
                          <v-text-field
                            outlined
                            label="Affiliation*"
                            class="mb-4"
                            background-color="white"
                            color="black"
                            placeholder="Example Univ."
                            hide-details
                            v-model="collaborator.affiliation"
                            :error-messages="
                              errors['collaborators.' + index + '.affiliation'] ? errors['collaborators.' + index + '.affiliation'] :''
                            "
                          ></v-text-field>
                          <div
                            class="v-text-field__details"
                            v-if="
                              errors['collaborators.' + index + '.affiliation']
                            "
                          >
                            <div class="v-messages theme--light error--text" role="alert">
                              <div class="v-messages__wrapper">
                                <div class="messages__message">{{ errors['collaborators.' + index + '.affiliation'][0] }}</div>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <div class="text-right pr-4 pb-4">
                  <v-btn small icon>
                    <v-icon @click.stop="deleteCollaborator(index)">
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </div>
              </v-card>
            </template>
          </transition-group>
          <div class="text-center mt-4 pb-6">
            <v-btn color="yellow" x-large @click="createCollaborator()">
              Add Collaborator
              <v-icon right large class="ml-4">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>

          <div class="login-btn text-center mt-6 mb-5">
            <v-btn
              class="font-weight-bold mr-4"
              color="grey lighten-2"
              large
              to="/Register"
            >
              Cancel
            </v-btn>
            <v-btn
              class="font-weight-bold"
              color="blue darken-2"
              dark
              large
              @click="confirm"
            >
              Confirm
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      errors: {},
      earlyCareerScientists: {},
      word_count: 0,
      termText: '',
      fields: {
        methods:"validate",
        first_name:"",
        middle_name:"",
        last_name:"",
        suffix:"",
        email:"",        
        affiliation:"",
        early_career:"0",
        proposal_title:"",
        proposal_abstract:"",
        collaborators:[],
      },
      briefRules: [() => this.word_count <= 150 || "Max 150 words"],
    };
  },
  computed: {
    first_name: {
      get() {
        return this.$store.state.first_name;
      },
      set(value) {
        this.$store.commit("updateFirstName", value);
      },
    },
    middle_name: {
      get() {
        return this.$store.state.middle_name;
      },
      set(value) {
        this.$store.commit("updateMiddleName", value);
      },
    },
    last_name: {
      get() {
        return this.$store.state.last_name;
      },
      set(value) {
        this.$store.commit("updateLastName", value);
      },
    },
    suffix: {
      get() {
        return this.$store.state.suffix;
      },
      set(value) {
        this.$store.commit("updateSuffix", value);
      },
    },
    email: {
      get() {
        return this.$store.state.email;
      },
      set(value) {
        this.$store.commit("updateEmail", value);
      },
    },
    affiliation: {
      get() {
        return this.$store.state.affiliation;
      },
      set(value) {
        this.$store.commit("updateAffiliation", value);
      },
    },
    early_career: {
      get() {
        if (!this.$store.state.early_career) {this.$store.commit("updateEarlyCareer", "0");}
        return this.$store.state.early_career;
      },
      set(value) {
        this.$store.commit("updateEarlyCareer", value);
      },
    },
    proposal_title: {
      get() {
        return this.$store.state.proposal_title;
      },
      set(value) {
        this.$store.commit("updateProposalTitle", value);
      },
    },
    proposal_abstract: {
      get() {
        return this.$store.state.proposal_abstract;
      },
      set(value) {
        this.$store.commit("updateProposalAbstract", value);
        this.word_count = value.match(/\S+/g).length;
      },
    },
    collaborators: {
      get() {
        return this.$store.state.collaborators;
      },
      set(value) {
        this.$store.commit("updateCollaborators", value);
      },
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      (async () => {
        await this.$axios.get("/api/masters/early_career_type").then((response) => {
          this.earlyCareerScientists = response.data;
        });
        await this.$axios.get("/api/masters/getTermText/0")
          .then((response) => {
            this.termText = response.data;
        });
      })();
    },
    createCollaborator() {
      const title = this.collaborators.length + 1;
      this.collaborators.push({ title: title });
    },
    deleteCollaborator(index) {
      this.collaborators.splice(index, 1);
    },
    confirm() {
      this.errors = {};
      this.fields.first_name = this.first_name;
      this.fields.middle_name = this.middle_name;
      this.fields.last_name = this.last_name;
      this.fields.suffix = this.suffix;
      this.fields.email = this.email;
      this.fields.affiliation = this.affiliation;
      this.fields.early_career = this.early_career;
      this.fields.proposal_title = this.proposal_title;
      this.fields.proposal_abstract = this.proposal_abstract;
      this.fields.collaborators = this.collaborators;
      this.$axios
        .post("/api/applicants", this.fields)
        .then((response) => {
          if (response.status === 200) {
            this.$router.push('/account/confirm')
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
          }
        });
    },
  },
};
</script>

<style>
.title-bg {
  background-color: #19407f;
}
/* transition */
.v-enter-active,
.v-leave-active,
.v-move {
  transition: opacity 0.3s;
}
.v-leave-active {
  /*    position: absolute; */
}
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.application {
  background: #000428; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #004e92,
    #000428
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #004e92,
    #000428
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: url(../../assets/bg.jpg) center center no-repeat;
  background-size: cover;
  padding-bottom: 100px;
}
</style>
