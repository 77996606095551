<template>
  <v-app>
    <v-container fluid class="top">
      <v-row justify="center" align="center" class="py-8 pb-4">
        <v-col lg="10">
          <h1 class="text-center mb-8 text-h4 title">
            Announcement of Opportunity for Hayabusa2 Samples
          </h1>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col lg="10" xl="8">
          <v-row justify="space-between" align="center" class="mb-8"
              style="background: rgba(255, 255, 255, 0.1)">
            <v-col
              md="8"
              class="py-2 px-9"
            >
              <h2 class="mb-1">New user:</h2>
              <p class="text-h6 title pl-9">
                Please click [Registration] then input your proposal information to an application form.
              </p>
            </v-col>
            <v-col md="4">
              <v-card class="mx-auto mb-3" max-width="320">
                <v-list color="lighten-1">
                  <v-list-item href="/account">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title"
                        >Registration
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col lg="10" xl="8">
          <v-row justify="space-between" align="center" class="mb-8"
              style="background: rgba(255, 255, 255, 0.1)">
            <v-col
              md="8"
              class="py-2 px-9"
            >
              <h2 class="mb-1">Existing user (user already has an account):</h2>
              <p class="text-h6 title pl-9">
                Please click [Login], then input your proposal information to an application form.<br>
                If you forget your password, click [Login], then reset your password by clicking “IF YOU FORGET YOUR PASSWORD >” placed under the account input window.
              </p>
            </v-col>
            <v-col md="4">
              <v-card class="mx-auto" max-width="320">
                <v-list color="lighten-1">
                  <v-list-item :href="loginUrl">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title"
                        >Login</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <p class="text-right my-2">
            <a href="/" class="white--text text-body-1">Back to top</a>
          </p>

          <p class="text-center my-2">
            Contact: <a
              href="JavaScript:mail_to('','')"
              class="white--text"
              >Astromaterials Science Research Group, JAXA</a>
          </p>

          <div class="text-center mb-4">
            <img src="../assets/logo.png" width="130" class="mx-auto mb-4" />
          </div>
        </v-col>
      </v-row>
      <div class="text-right pr-4 pb-4 text-subtitle-1 opacity">©JAXA</div>
      <script type="application/javascript">
        function converter(M) {
          var str="", str_as="";
          for(var i=0;i<M.length;i++){
            str_as = M.charCodeAt(i);
            str += String.fromCharCode(str_as + 1);
          }
          return str;
        }
        function mail_to(k_1,k_2)
        {
          eval(String.fromCharCode(108,111,99,97,116,105,111,110,46,104,114,101,102,32,61,32,39,109,97,105,108,116,111,58) 
          + escape(k_1) + 
          converter(String.fromCharCode(73,64,87,64,44,98,116,113,96,115,104,110,109,44,96,110,63,105,96,119,96,45,105,111,62,114,116,97,105,100,98,115,60)) 
          + escape(k_2) + "'");
        } 
      </script>
    </v-container>
  </v-app>
</template>

<style scoped lang="scss">
.top {
  background: url(../assets/bg.jpg) center bottom no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  min-height: 100vh;
}
.opacity {
  opacity: 0.4;
}
.v-application {
  .text-h4.title {
    font-family: "Titillium Web", sans-serif !important;
    font-weight: bold;
    font-size: 2.5rem !important;
  }
  .text-subtitle-1.title {
    font-family: "Titillium Web", sans-serif !important;
  }
}
</style>

<script>
export default {
  data() {
    return {
      loginUrl:"",
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getLoginUrl`)
        .then((response) => {
          this.loginUrl = response.data;
        });
    })();
  },
};
</script>