<template>
  <v-app>
    <v-container fluid class="top">
      <v-row justify="center" align="center" class="py-8 pb-4">
        <v-col lg="10">
          <h1 class="text-center mb-8 text-h4 title">
            Announcement of Opportunity for Hayabusa2 Samples
          </h1>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col lg="10" xl="8" style="background: rgba(255, 255, 255, 0.1)" class="py-5">
          <v-row justify="space-between" align="center" class="mb-2">
            <v-col md="12" class="py-2 px-9">
              <h2 class="mb-1">For accepted proposer</h2>
              <p class="text-h6 title pl-5">
                At first, please read carefully the Notes to the accepted proposers.
              </p>
              <ul class="white--text text-body-1 title pl-9">
                <li class="pb-2">
                  <a href="https://data.darts.isas.jaxa.jp/pub/curation/RyuguAO/paper%20works/0_Notes%20on%20loaned%20Ryugu%20samples%20to%20accepted%20proposers_AO.pdf" target="_blank" style="color: white"
                    >Notes on loaned Ryugu samples to accepted proposers AO (PDF)</a
                  >
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-col md="8" class="py-0 px-9">
              <h2 class="mb-1">Templates</h2>
              <span class="text-h6 title pl-5">&lt;Before allocation&gt;</span>
            </v-col>
          </v-row>
          <!-- Before allocation-->
          <v-row justify="center">
            <v-col md="4">
              <v-card class="mx-auto" max-width="320" height="90">
                <v-list>
                  <v-list-item href="https://data.darts.isas.jaxa.jp/pub/curation/RyuguAO/paper%20works/1_Investigation%20plan%20sheet_AO.docx" target="_blank">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        Investigation plan sheet<br />(.docx)
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-download</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card class="mx-auto" max-width="320" height="90">
                <v-list>
                  <v-list-item
                    href="https://data.darts.isas.jaxa.jp/pub/curation/RyuguAO/paper%20works/2_Terms%20and%20conditions_AO_Jan%202022.pdf"
                    target="_blank"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        Terms and conditions<br />(PDF)
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card class="mx-auto mb-2" max-width="320" height="90">
                <v-list>
                  <v-list-item
                    href="https://data.darts.isas.jaxa.jp/pub/curation/RyuguAO/paper%20works/3_Support_letter_AO_example.docx"
                    target="_blank"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        Support letter<br />(format example in .docx)
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-download</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>

          <!-- During the research period -->
          <v-row justify="space-between" align="center">
            <v-col md="8" class="py-0 px-9">
              <span class="text-h6 title pl-5"
                >&lt;During the research period&gt;</span
              >
            </v-col>
          </v-row>
          <v-row justify="left">
            <v-col md="4">
              <v-card class="mx-auto" max-width="320" height="90">
                <v-list>
                  <v-list-item href="https://data.darts.isas.jaxa.jp/pub/curation/RyuguAO/paper%20works/4_Additional%20investigation%20plan%20sheet_AO.docx" target="_blank">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        Additional investigation<br />plan sheet (.docx)
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-download</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card class="mx-auto" max-width="320" height="90">
                <v-list>
                  <v-list-item
                    href="https://data.darts.isas.jaxa.jp/pub/curation/RyuguAO/paper%20works/5_Status%20report_AO.docx"
                    target="_blank"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        Status report (.docx)
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-download</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <!-- At the end of the research -->
          <v-row justify="space-between" align="center" class="mt-6">
            <v-col md="8" class="py-0 px-9">
              <span class="text-h6 title pl-5"
                >&lt;At the end of the research&gt;</span
              >
            </v-col>
          </v-row>
          <v-row justify="left">
            <v-col md="4">
              <v-card class="mx-auto" max-width="320">
                <v-list>
                  <v-list-item href="https://data.darts.isas.jaxa.jp/pub/curation/RyuguAO/paper%20works/At%20the%20end%20of%20the%20research_AO.zip" target="_blank">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        Zip file:
                      </v-list-item-title>
                      <ul>
                        <li>Summary sheet (.docx)</li>
                        <li>Sample return form (.xlsx)</li>
                        <li>Sample images (example of images in ppt or other formats)</li>
                      </ul>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-download</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="space-between" align="center" class="mt-10">
            <v-col md="12" class="py-0 px-9">
              <h2 class="mb-1">Information of sample container</h2>
              <p class="text-h6 title pl-5">JAXA curation prepared JAXA container to package up samples for transfer to an investigator's institution, in a high-purity nitrogen atmosphere. </p>
              
              <ul class="white--text text-body-1 title pl-9">
                <li class="pb-2">
                  <a href="https://data.darts.isas.jaxa.jp/pub/curation/RyuguAO/paper%20works/7.%20Instruction_to_open_FFTC_sample_holder_20230404.pdf" target="_blank" style="color: white"
                    >Sample holder and FFTC (PDF)</a
                  >
                </li>
                <li class="pb-2">
                  <a href="https://doi.org/10.1186/s40623-020-01267-2" target="_blank" style="color: white"
                    >Ito et al., The universal sample holders of microanalytical instruments of FIB, TEM, NanoSIMS, and STXM-NEXAFS for the coordinated analysis of extraterrestrial materials, Earth Planets Space 72, 133 (2020)</a
                  >
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col lg="10" xl="8">
          <p class="text-right my-2">
            <a href="/" class="white--text text-body-1">Back to top</a>
          </p>

          <p class="text-center my-2">
            Contact:
            <a href="JavaScript:mail_to('','')" class="white--text"
              >Astromaterials Science Research Group, JAXA</a
            >
          </p>

          <div class="text-center mb-4">
            <img src="../assets/logo.png" width="130" class="mx-auto mb-4" />
          </div>
        </v-col>
      </v-row>
      <div class="text-right pr-4 pb-4 text-subtitle-1 opacity">©JAXA</div>
      <script type="application/javascript">
        function converter(M) {
          var str="", str_as="";
          for(var i=0;i<M.length;i++){
            str_as = M.charCodeAt(i);
            str += String.fromCharCode(str_as + 1);
          }
          return str;
        }
        function mail_to(k_1,k_2)
        {
          eval(String.fromCharCode(108,111,99,97,116,105,111,110,46,104,114,101,102,32,61,32,39,109,97,105,108,116,111,58) 
          + escape(k_1) + 
          converter(String.fromCharCode(73,64,87,64,44,98,116,113,96,115,104,110,109,44,96,110,63,105,96,119,96,45,105,111,62,114,116,97,105,100,98,115,60)) 
          + escape(k_2) + "'");
        } 
      </script>
    </v-container>
  </v-app>
</template>

<style scoped lang="scss">
.top {
  background: url(../assets/bg.jpg) center bottom no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  min-height: 100vh;
}
.opacity {
  opacity: 0.4;
}
.v-application {
  .text-h4.title {
    font-family: "Titillium Web", sans-serif !important;
    font-weight: bold;
    font-size: 2.5rem !important;
  }
  .text-subtitle-1.title {
    font-family: "Titillium Web", sans-serif !important;
  }
}
</style>

<script>
export default {
  data() {
    return {
      loginUrl: "",
    };
  },
  mounted() {
    (async () => {
      this.$axios.get(`/api/masters/getLoginUrl`).then((response) => {
        this.loginUrl = response.data;
      });
    })();
  },
};
</script>
