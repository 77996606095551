<template>
  <v-app>
    <v-container fluid class="top">
      <v-row justify="center" align="center" class="py-8 pb-4">
        <v-col lg="10">
          <h1 class="text-center mb-8 text-h4 title">
            Announcement of Opportunity for Hayabusa2 Samples
          </h1>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col lg="10" xl="8">
          <v-row justify="space-between" align="center" class="mb-8">
            <v-col
              md="8"
              class="py-2 px-9"
              style="background: rgba(255, 255, 255, 0.1)"
            >
              <h2 class="mb-1">Key milestones</h2>
              <ul class="text-h6 title pl-9">
                <li>Call for proposals for the 5th AO: June 26, 2024</li>
                <li>Notice of Intent to propose (mandatory): August 7, 2024</li>
                <li><span class="yellow--text font-weight-bold">Sample request submission: September 4, 2024</span></li>
                <li>Decision announcement: December, 2024</li>
                <li>Sample distribution: January, 2025</li>
              </ul>
            </v-col>
            <v-col md="4">
              <v-card class="mx-auto mb-3" max-width="320">
                <v-list color="grey lighten-1">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title"
                        >Registration
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="text-center text-subtitle-1 title"
                        >(Closed)
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card class="mx-auto" max-width="320">
                <v-list color="lighten-1">
                  <v-list-item :href="loginUrl">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title"
                        >Login</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card class="mx-auto mt-6" max-width="320" color="transparent" flat>
                <ul class="white--text text-body-1 title">
                  <li class="pb-2"><a href="https://data.darts.isas.jaxa.jp/pub/curation/RyuguAO/quick_manual.pdf" target="_blank" style="color:white;">Quick manual</a></li>
                  <li class="pb-2"><a href="https://curation.isas.jaxa.jp/ao/index.html" target="_blank" style="color:white;">Announcement of Opportunity for Hayabusa(Itokawa) samples</a></li>
                </ul>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="space-between" align="center" class="mb-8">
            <p
              class="mb-0 pa-6 text-h6 title"
              style="background: rgba(255, 255, 255, 0.1)"
            >
              The Astromaterials Science Research Group (ASRG) of ISAS/JAXA is calling for
              proposals for Hayabusa2, Ryugu, returned samples! Details of the fifth announcement
              of opportunity (AO5) are found <a href="files/guidebookAO5.pdf" target="_blank" style="color:white;">here</a>.
              The available samples for AO5 are indicated in the <a href="https://darts.isas.jaxa.jp/app/curation/ryugu/" target="_blank" style="color:white;">Ryugu Database System</a>.
              All proposals will be subjected to peer review, and allocated samples to successful proposers will be available for one year.<br>
              We would like to thank all the reviewers who contributed to reviewing the proposals from AO1 to AO4. With their permission,
              the reviewers’ names are listed <a href="https://curation.isas.jaxa.jp/ao/ryugu/reviewers/" target="_blank" style="color:white;">here</a>.
            </p>
          </v-row>
          <v-row justify="center">
            <v-col md="4">
              <v-card class="mx-auto" max-width="320" height="90">
                <v-list>
                  <v-list-item href="files/guidebookAO5.pdf" target="_blank">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        Guidebook for Proposers
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="text-center text-subtitle-1 title"
                        >(last update June, 2024)
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card class="mx-auto" max-width="320" height="90">
                <v-list>
                  <v-list-item href="files/Ryugu_AO-proposal-22A_template.docx" target="_blank">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        Proposal template (.docx)   
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-download</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card class="mx-auto mb-2" max-width="320" height="90">
                <v-list>
                  <v-list-item href="https://darts.isas.jaxa.jp/app/curation/ryugu/" target="_blank">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        Ryugu sample database
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          
          <v-row justify="center">
            <v-col md="4">
              <v-card class="mx-auto" max-width="320" height="90">
                <v-list>
                  <v-list-item href="https://curation.isas.jaxa.jp/publications/ryugu/" target="_blank">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        References related to<br> Ryugu sample analysis
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card class="mx-auto" max-width="320" height="90">
                <v-list>
                  <v-list-item href="https://curation.isas.jaxa.jp/ao/ryugu/proposals/" target="_blank">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        Accepted Proposals in<br>previous AOs 
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card class="mx-auto mb-2" max-width="320" height="90">
                <v-list height="90">
                  <v-list-item href="/for_proposer">
                    <v-list-item-content>
                      <v-list-item-title class="text-center text-h6 title">
                        FOR ACCEPTED<br> PROPOSERS
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="pt-2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>

          <p class="text-center my-2">
            Contact: <a
              href="JavaScript:mail_to('','')"
              class="white--text"
              >Astromaterials Science Research Group, JAXA</a>
     </p>

          <div class="text-center mb-4">
            <img src="../assets/logo.png" width="130" class="mx-auto mb-4" />
          </div>
        </v-col>
      </v-row>
      <div class="text-right pr-4 pb-4 text-subtitle-1 opacity">©JAXA</div>
      
      <script type="application/javascript">
        function converter(M) {
          var str="", str_as="";
          for(var i=0;i<M.length;i++){
            str_as = M.charCodeAt(i);
            str += String.fromCharCode(str_as + 1);
          }
          return str;
        }
        function mail_to(k_1,k_2)
        {
          eval(String.fromCharCode(108,111,99,97,116,105,111,110,46,104,114,101,102,32,61,32,39,109,97,105,108,116,111,58) 
          + escape(k_1) + 
          converter(String.fromCharCode(73,64,87,64,44,98,116,113,96,115,104,110,109,44,96,110,63,105,96,119,96,45,105,111,62,114,116,97,105,100,98,115,60)) 
          + escape(k_2) + "'");
        } 
      </script>
      <!-- Global site tag (gtag.js) - Google Analytics -->
      <script type="application/javascript" async src="https://www.googletagmanager.com/gtag/js?id=G-QNP7GM3YRG"></script>
      <script type="application/javascript">
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-QNP7GM3YRG');
      </script>
    </v-container>
  </v-app>
</template>

<style scoped lang="scss">
.top {
  background: url(../assets/bg.jpg) center bottom no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  min-height: 100vh;
}
.opacity {
  opacity: 0.4;
}
.v-application {
  .text-h4.title {
    font-family: "Titillium Web", sans-serif !important;
    font-weight: bold;
    font-size: 2.5rem !important;
  }
  .text-subtitle-1.title {
    font-family: "Titillium Web", sans-serif !important;
  }
}
</style>

<script>
export default {
  data() {
    return {
      loginUrl:"",
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getLoginUrl`)
        .then((response) => {
          this.loginUrl = response.data;
        });
    })();
  },
};
</script>